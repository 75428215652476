const _temp0 = require("./Frames Site V6 L40-01.jpg");
const _temp1 = require("./Frames Site V6 L40-02.jpg");
const _temp2 = require("./Frames Site V6 L40-03.jpg");
const _temp3 = require("./Frames Site V6 L40-04.jpg");
const _temp4 = require("./Frames Site V6 L40-05.jpg");
const _temp5 = require("./Frames Site V6 L40-06.jpg");
const _temp6 = require("./Frames Site V6 L40-07.jpg");
const _temp7 = require("./Frames Site V6 L40-08.jpg");
const _temp8 = require("./Frames Site V6 L40-09.jpg");
const _temp9 = require("./Frames Site V6 L40-10.jpg");
const _temp10 = require("./Frames Site V6 L40-11.jpg");
const _temp11 = require("./Frames Site V6 L40-12.jpg");
const _temp12 = require("./Frames Site V6 L40-13.jpg");
const _temp13 = require("./Frames Site V6 L40-14.jpg");
const _temp14 = require("./Frames Site V6 L40-15.jpg");
const _temp15 = require("./Frames Site V6 L40-16.jpg");
const _temp16 = require("./Frames Site V6 L40-17.jpg");
const _temp17 = require("./Frames Site V6 L40-18.jpg");
const _temp18 = require("./Frames Site V6 L40-19.jpg");
const _temp19 = require("./Frames Site V6 L40-20.jpg");
const _temp20 = require("./Frames Site V6 L40-21.jpg");
const _temp21 = require("./Frames Site V6 L40-22.jpg");
const _temp22 = require("./Frames Site V6 L40-23.jpg");
const _temp23 = require("./Frames Site V6 L40-24.jpg");
const _temp24 = require("./Frames Site V6 L40-25.jpg");
const _temp25 = require("./Frames Site V6 L40-26.jpg");
const _temp26 = require("./Frames Site V6 L40-27.jpg");
const _temp27 = require("./Frames Site V6 L40-28.jpg");
const _temp28 = require("./Frames Site V6 L40-29.jpg");
const _temp29 = require("./Frames Site V6 L40-30.jpg");
const _temp30 = require("./Frames Site V6 L40-31.jpg");
const _temp31 = require("./Frames Site V6 L40-32.jpg");
const _temp32 = require("./Frames Site V6 L40-33.jpg");
const _temp33 = require("./Frames Site V6 L40-34.jpg");
const _temp34 = require("./Frames Site V6 L40-35.jpg");
const _temp35 = require("./Frames Site V6 L40-36.jpg");
const _temp36 = require("./Frames Site V6 L40-37.jpg");
const _temp37 = require("./Frames Site V6 L40-38.jpg");
const _temp38 = require("./Frames Site V6 L40-39.jpg");
const _temp39 = require("./Frames Site V6 L40-40.jpg");
const _temp40 = require("./Frames Site V6 L40-41.jpg");
const _temp41 = require("./Frames Site V6 L40-42.jpg");
const _temp42 = require("./Frames Site V6 L40-43.jpg");
const _temp43 = require("./Frames Site V6 L40-44.jpg");
const _temp44 = require("./Frames Site V6 L40-45.jpg");
const _temp45 = require("./Frames Site V6 L40-46.jpg");
const _temp46 = require("./Frames Site V6 L40-47.jpg");
const _temp47 = require("./Frames Site V6 L40-48.jpg");
const _temp48 = require("./Frames Site V6 L40-49.jpg");
const _temp49 = require("./Frames Site V6 L40-50.jpg");
module.exports = {
  "Frames Site V6 L40-01": _temp0,
  "Frames Site V6 L40-02": _temp1,
  "Frames Site V6 L40-03": _temp2,
  "Frames Site V6 L40-04": _temp3,
  "Frames Site V6 L40-05": _temp4,
  "Frames Site V6 L40-06": _temp5,
  "Frames Site V6 L40-07": _temp6,
  "Frames Site V6 L40-08": _temp7,
  "Frames Site V6 L40-09": _temp8,
  "Frames Site V6 L40-10": _temp9,
  "Frames Site V6 L40-11": _temp10,
  "Frames Site V6 L40-12": _temp11,
  "Frames Site V6 L40-13": _temp12,
  "Frames Site V6 L40-14": _temp13,
  "Frames Site V6 L40-15": _temp14,
  "Frames Site V6 L40-16": _temp15,
  "Frames Site V6 L40-17": _temp16,
  "Frames Site V6 L40-18": _temp17,
  "Frames Site V6 L40-19": _temp18,
  "Frames Site V6 L40-20": _temp19,
  "Frames Site V6 L40-21": _temp20,
  "Frames Site V6 L40-22": _temp21,
  "Frames Site V6 L40-23": _temp22,
  "Frames Site V6 L40-24": _temp23,
  "Frames Site V6 L40-25": _temp24,
  "Frames Site V6 L40-26": _temp25,
  "Frames Site V6 L40-27": _temp26,
  "Frames Site V6 L40-28": _temp27,
  "Frames Site V6 L40-29": _temp28,
  "Frames Site V6 L40-30": _temp29,
  "Frames Site V6 L40-31": _temp30,
  "Frames Site V6 L40-32": _temp31,
  "Frames Site V6 L40-33": _temp32,
  "Frames Site V6 L40-34": _temp33,
  "Frames Site V6 L40-35": _temp34,
  "Frames Site V6 L40-36": _temp35,
  "Frames Site V6 L40-37": _temp36,
  "Frames Site V6 L40-38": _temp37,
  "Frames Site V6 L40-39": _temp38,
  "Frames Site V6 L40-40": _temp39,
  "Frames Site V6 L40-41": _temp40,
  "Frames Site V6 L40-42": _temp41,
  "Frames Site V6 L40-43": _temp42,
  "Frames Site V6 L40-44": _temp43,
  "Frames Site V6 L40-45": _temp44,
  "Frames Site V6 L40-46": _temp45,
  "Frames Site V6 L40-47": _temp46,
  "Frames Site V6 L40-48": _temp47,
  "Frames Site V6 L40-49": _temp48,
  "Frames Site V6 L40-50": _temp49
}