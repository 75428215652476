/* eslint-disable node/no-missing-require */
/* eslint-disable no-plusplus */
/* eslint-disable node/no-missing-import */
/* eslint-disable no-undef */
import { Modal } from "bootstrap";

import firstFrame from "../img/parallax/Frames Site V6 L40-01.jpg";

const FRAME_COUNT = 50;

const html = document.documentElement;
const canvas = document.getElementById("home-parallax");
const scrollIcon = document.querySelector(".scroll-icon");
const context = canvas.getContext("2d");
const firstContainer = document.querySelector(".home__first");
const secondContainer = document.querySelector(".home__second");
const thirdContainer = document.querySelector(".home__third");
const videoButton = document.querySelector(".show-welcome-video");
const videoModalEl = document.getElementById("videoModal");
const videoModal = new Modal(videoModalEl);
const video = document.querySelector(".welcome-video iframe");

const connectionType = navigator?.connection?.effectiveType;

if (connectionType !== "2g" && connectionType !== "3g") {
  const loadCanvas = () => {
    const images = require("../img/parallax/*.jpg");

    const currentFrame = (index) =>
      images[`Frames Site V6 L40-${index.toString().padStart(2, "0")}`];

    const imagesArray = [null];
    const preloadImages = () => {
      for (let i = 1; i <= FRAME_COUNT; i++) {
        imagesArray[i] = new Image();
        imagesArray[i].src = currentFrame(i);
      }
    };

    preloadImages();

    const updateImage = (index) => {
      context.drawImage(imagesArray[index], 0, 0);
    };

    const handleCanvas = () => {
      const { scrollTop } = html;
      const maxScrollTop = html.scrollHeight - window.innerHeight;
      const scrollFraction = scrollTop / maxScrollTop;
      const frameIndex = Math.min(
        FRAME_COUNT - 1,
        Math.ceil(scrollFraction * FRAME_COUNT)
      );
      requestAnimationFrame(() => updateImage(frameIndex + 1));
    };

    window.addEventListener("scroll", () => {
      handleCanvas();
    });
  };
  loadCanvas();
}

const img = new Image();
img.src = firstFrame;
canvas.width = 1920;
canvas.height = 1080;
img.onload = () => context.drawImage(img, 0, 0);

const handleAnimation = () => {
  const windowHeight = window.innerHeight;
  const { top: secondContainerTop, width: secondContainerWidth } =
    secondContainer.getBoundingClientRect();
  const { top: thirdContainerTop } = thirdContainer.getBoundingClientRect();

  if (secondContainerWidth > 0 && secondContainerTop - windowHeight / 2 < 140) {
    firstContainer.classList.add("slide-right-out");
  }

  if (secondContainerWidth > 0 && secondContainerTop - windowHeight / 2 < 0) {
    secondContainer.classList.add(
      "top-50vh",
      "position-fixed",
      "visible",
      "slide-right-in"
    );
  }

  if (thirdContainerTop - windowHeight / 2 < 100) {
    thirdContainer.classList.add(
      "visible",
      "position-fixed",
      "top-47vh",
      "flip-horizontal-bottom"
    );

    scrollIcon.classList.add("invisible");

    secondContainer.classList.remove("visible");
    secondContainer.classList.add("invisible");
  }
};

videoButton.addEventListener("click", () => {
  videoModal.show();
});

videoModalEl.addEventListener("hidden.bs.modal", () => {
  video.src = "";
});

videoModalEl.addEventListener("shown.bs.modal", () => {
  video.src = "https://www.youtube.com/embed/q-HC1_f-pc4";
});

window.addEventListener("scroll", () => {
  handleAnimation();
});
